<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        size="28px"
        v-bind="attrs"
        class="ml-2"
        color="primary"
        v-on="on"
      >
        {{ getShortName($auth.user()) }}
      </v-avatar>
    </template>
    <v-list v-if="$auth.check()">
      <div class="ml-2 pb-3 pt-2">
        <v-avatar size="28px" color="primary">
          {{ getShortName($auth.user()) }}
        </v-avatar>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1"></span>
          <small class="text--disabled">{{ $auth.user().email }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <router-link :to="{ name:'pages-account-settings' }">
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <!-- Email -->
      <!--      <v-list-item link>-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiEmailOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Inbox</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!-- Chat -->
      <!--      <v-list-item link>-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiChatOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Chat</v-list-item-title>-->
      <!--        </v-list-item-content>-->

      <!--        <v-list-item-action>-->
      <!--          <v-badge-->
      <!--            inline-->
      <!--            color="error"-->
      <!--            content="2"-->
      <!--          >-->
      <!--          </v-badge>-->
      <!--        </v-list-item-action>-->
      <!--      </v-list-item>-->

      <!--      <v-divider class="my-2"></v-divider>-->

      <!-- Settings -->
<!--      <v-list-item link>-->
<!--        <v-list-item-icon class="me-2">-->
<!--          <v-icon size="22">-->
<!--            {{ icons.mdiCogOutline }}-->
<!--          </v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content>-->
<!--          <v-list-item-title>Settings</v-list-item-title>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->

      <!-- Pricing -->
      <!--      <v-list-item link>-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiCurrencyUsd }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Pricing</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!-- FAQ -->
      <!--      <v-list-item link>-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiHelpCircleOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>FAQ</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        link
        @click.prevent="logout"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="!$auth.check()">
      <router-link :to="{ name:'pages-login' }">
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLoginVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Log In</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <router-link :to="{ name:'pages-register' }">
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sign Up</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiLoginVariant,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiLoginVariant,
      },
    }
  },
  methods: {
    getShortName(user) {
      return (user.name || user.email)[0].toUpperCase()
    },
    logout() {
      this.$auth.logout()

      // @TODO: !!! load cached initial page !!!
      this.$router.go('/')

      // @TODO: flush all stores on logout
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
