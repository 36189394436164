import axios from 'axios'

const defaultProject = {
  id: false,
  name: 'Choose Project',
}

const localStorageKey = 'current-project'

const storedCurrentProject = JSON.parse(
  localStorage.getItem(localStorageKey),
) || null

const store = {
  state: {
    currentProject: storedCurrentProject || defaultProject,
    currentProjectName: (storedCurrentProject ? storedCurrentProject.name : '') || defaultProject.name,
    projects: [],
  },
  mutations: {
    SET_CURRENT_PROJECT(state, currentProject) {
      state.currentProject = currentProject
      state.currentProjectName = currentProject.name
    },
    SET_PROJECTS(state, projects) {
      state.projects = projects
    },
  },
  actions: {
    async fetchProjects({ commit }) {
      try {
        let response = await axios.get('project/list')
        commit('SET_PROJECTS', response.data.data)
      } catch (e) {
        alert(e)
      }
    },
    setCurrentProjectById({ dispatch, state }, projectId) {
      for (const proj of state.projects) {
        if (projectId === proj.id) {
          dispatch('setCurrentProject', proj)
          break
        }
      }
    },
    setCurrentProject({ commit, dispatch }, payload) {
      // dispatch('addAlertMsg', {type: 'success', text: 'Project switched'})
      commit('SET_CURRENT_PROJECT', payload)
      localStorage.setItem(
        localStorageKey,
        JSON.stringify(payload),
      )
    },
  },
}

export default store
