import axios from 'axios'

const store = {
  state: {
    serverStatsSynced: false,
    serverStatsSources: [],
    serverStatsModules: [],
  },
  mutations: {
    SET_SERVER_STATS_MODULES_AND_SOURCES(state, sources, modules) {
      state.serverStatsSources = sources
      state.serverStatsModules = modules
      state.serverStatsSynced = true
    },
  },
  actions: {
    async fetchSourcesAndModules({ commit, state }) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }

      try {
        let response = await axios.get(
          'get-service-info/sources-n-modules/' + state.currentProject.id
        )
        commit(
          'SET_SERVER_STATS_MODULES_AND_SOURCES',
          response.data.data.sources,
          response.data.data.modules
        )
      } catch (e) {
        // @TODO: error processing
        alert(e)
      }
    },
  },
}

export default store
