import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiFileDocument,
  mdiViewList, mdiAccountMultiple, mdiLink, mdiServer, mdiArchive,
} from '@mdi/js'

const store = {
  state: {
    enabledModules: [
      {
        title: 'MODULES',
        modules: [
          {
            showInSideNav: true,
            icon: mdiAccountMultiple,
            routeTo: {name: 'teams'},
            title: 'Teams'
          }, {
            showInSideNav: true,
            icon: mdiLink,
            routeTo: {name: 'quick-links'},
            title: 'Quick Links'
          }, {
            showInSideNav: true,
            icon: mdiFileDocument,
            routeTo: {name: 'documents', id: null},
            title: 'Documents'
          }, {
            showInSideNav: true,
            icon: mdiViewList,
            routeTo: {name: 'todo-lists'},
            title: 'TO-DO'
          },
        ]
      }, {
        title: 'SERVER',
        modules: [
          {
            showInSideNav: true,
            icon: mdiServer,
            routeTo: {name: 'server-health'},
            title: 'Server Health'
          }, {
            showInSideNav: true,
            icon: mdiArchive,
            routeTo: {name: 'dumps'},
            title: 'Dumps'
          },
        ]
      },
    ],
  },
  mutations: {

  },
  actions: {

  },
}

export default store
