<template>
  <v-list
    v-if="getFavoriteQuickLinks().length"
    dense
    class="mt-4 pa-0"
  >
    <nav-menu-section-title title="LINKS"></nav-menu-section-title>
    <v-list-item-group color="primary">
      <v-list-item
        v-for="(item, i) in getFavoriteQuickLinks()"
        :key="i"
        dense
      >
        <v-list-item-content>
          <v-list-item-title @click="open(item)">
            {{ item.label }}
            <v-icon
              small
              class="ml-2"
            >
              {{ icons.mdiArrowTopRight }}
            </v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiArrowTopRight,
} from '@mdi/js'
import NavMenuSectionTitle from '../../layouts/components/vertical-nav-menu/components/NavMenuSectionTitle.vue'
import { openNewTab } from '@/helpers/helpers'

export default {
  components: {
    NavMenuSectionTitle,
  },
  data() {
    return {
      favorites: [],
      icons: {
        mdiArrowTopRight,
      },
      selected: [],
    }
  },

  watch: {
    async currentProject() {
      await this.$store.dispatch('fetchLinks')
    },
  },
  methods: {
    open(item) {
      openNewTab(item.link)
    },

    getFavoriteQuickLinks() {
      return this.quickLinksLinks.filter(link => link.favorite && !link._deleted)
    },
  },
  computed: {
    ...mapState([
      'quickLinksLinks',
      'currentProject'
    ]),
  },
  async mounted() {
    await this.$store.dispatch('fetchLinks')
  },
}
</script>

<style>
.v-list-item--dense, .v-list--dense .v-list-item {
  height: 36px;
  min-height: 36px;
}
</style>
