<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="on"
        class="ms-3"
      >
        <v-icon>
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="!notifications || !notifications.length"
                   class="text-sm pa-8">
        <v-list-item-title>
          You didn't get any notifications yet
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        dense
        v-for="(item, index) in notifications"
        :key="index"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiBellOutline } from '@mdi/js'
import {mapState} from "vuex";

export default {
  data() {
    return {
      notifications: []
    }
  },
  setup() {
    return {
      icons: {
        mdiBellOutline,
      },
    }
  },
  computed: {
    ...mapState([
      'themeSettings'
    ]),
  },
  watch: {
    themeSettings() {
      if (this.themeSettings.isDark !== undefined) {
        this.$vuetify.theme.dark = this.themeSettings.isDark
      }
    }
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.dispatch('setThemeSettings', {
        isDark: this.$vuetify.theme.dark
      })
    }
  }
}
</script>
