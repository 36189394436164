import axios from 'axios'

export default class Syncer {
  constructor(settings) {
    this.useLocalstorage = settings.useLocalstorage || true
    this.localstorageKey = settings.localstorageKey

    this.endpointUrl = settings.endpointUrl || 'syncer/sync'

    this.idFieldName = settings.idFieldName || 'id'

    this.internalKey = settings.internalKey

    this.storeStateKey = settings.storeStateKey
    this.storeMutationKey = settings.storeMutationKey
  }

  async sync(state, commit, projectId, id, items, extraKey = '') {
    const localStorageKey = this.__getLocalStorageKey(projectId, id, extraKey)

    items.map(async item => {
      if ((item._synced === false
          || item._new === true
          || item._deleted === true
          || item._archived === true
      ) && !item._skip_processing
      ) {
        switch (true) {
          case item._deleted:
            await this.#updateItemInStore(state, commit, item, null, localStorageKey)
            if (item[this.idFieldName]) {
              if (!await this._delete(item, projectId)) {
                // @TODO: re-try in a while
                await this.#updateItemInStore(state, commit, null, item, localStorageKey)
              }
            }
            break
          case item._archived:
            await this.#updateItemInStore(state, commit, item)
            if (!await this._archive(item, projectId)) {
              // @TODO: re-try in a while
              await this.#updateItemInStore(state, commit, null, item, localStorageKey)
            }
            break

          case item._new || !item._synced:
            // console.log('sync start', JSON.stringify(item))

            if (!item._in_sync) {
              item._in_sync = true
              const savedItem = await this._save(item, projectId)

              // console.log('sync end', JSON.stringify(item))
              if (savedItem[this.idFieldName]) {
                if (this.internalKey && item[this.internalKey]) {
                  savedItem[this.internalKey] = item[this.internalKey]
                }

                savedItem._synced = true

                await this.#updateItemInStore(state, commit, item, savedItem, localStorageKey)
              }

              item._in_sync = false
            }
            break
        }
      }
    })
  }

  async getItems(commit, projectId, id = null, extraKey = '') {
    const localStorageKey = this.__getLocalStorageKey(projectId, id, extraKey)

    try {
      const res = await axios.post(this.endpointUrl, {
        action: 'getList',
        type: this.localstorageKey,
        project_id: projectId,
        id,
      })

      if (res.status == 200) {
        // test for status you want, etc
        console.log(res.status)
      }

      await this.#updateItemsInStore(commit, res.data.entities || [], localStorageKey)
    } catch (e) {
      // @TODO: process
      console.log(e)

      if (this.useLocalstorage) {
        return JSON.parse(
          localStorage.getItem(localStorageKey),
        ) || []
      }
    }

    return []
  }

  async customAction(actionCode, projectId, id = null, extraKey = '') {
    const localStorageKey = this.__getLocalStorageKey(projectId, id, extraKey)

    try {
      const res = await axios.post(this.endpointUrl, {
        action: actionCode,
        type: this.localstorageKey,
        project_id: projectId,
        id,
      })

      if (res.status == 200) {
        // test for status you want, etc
        // console.log(res.status)
      }

      if (this.useLocalstorage && localStorageKey) {
        localStorage.setItem(
          localStorageKey,
          JSON.stringify(res.data),
        )
      }

      return res.data
    } catch (e) {
      // @TODO: process
      console.log(e)

      if (this.useLocalstorage) {
        return JSON.parse(
          localStorage.getItem(localStorageKey),
        )
      }
    }

    return {}
  }

  __getLocalStorageKey(projectId, id = null, extraKey = '') {
    return `${projectId}.${id ? `${id}.` : ''
    }${extraKey ? `${extraKey}.` : ''
    }${this.localstorageKey}`
  }

  async _save(entity, projectId) {
    try {
      const res = await axios.post(this.endpointUrl, {
        action: 'save',
        type: this.localstorageKey,
        project_id: projectId,
        entity,
      })

      if (res.status == 200) {
        // test for status you want, etc
        console.log(res.status)
      }
      if (res.data.error === true) {
        console.log(entity, res.data)
        throw 'Can not sync entity'
      }

      return res.data.entity
    } catch (e) {
      // @TODO: process
      console.log(e)
      throw e
    }

    return false
  }

  async _delete(entity, projectId) {
    try {
      const res = await axios.post(this.endpointUrl, {
        action: 'delete',
        type: this.localstorageKey,
        project_id: projectId,
        id: entity[this.idFieldName],
      })

      if (res.status == 200) {
        // test for status you want, etc
        // console.log(res.data.entity[this.idFieldName])
      }

      return res.data.deleted
    } catch (e) {
      // @TODO: process
      console.log(e)
    }

    return false
  }

  async _archive(entity, projectId) {
    try {
      const res = await axios.post(this.endpointUrl, {
        action: 'archive',
        type: this.localstorageKey,
        project_id: projectId,
        id: entity[this.idFieldName],
      })

      if (res.status == 200) {
        // test for status you want, etc
        console.log(res.status[this.idFieldName])
      }

      return res.data.archived
    } catch (e) {
      // @TODO: process
      console.log(e)
    }

    return false
  }

  async #updateItemInStore(state, commit, orig = null, newItem = null, localStorageKey = '') {
    if (!Array.isArray(this.storeStateKey)) {
      this.storeStateKey = [this.storeStateKey]
    }

    let stateItems = state
    for (const key of this.storeStateKey) {
      stateItems = stateItems[key]
    }

    if (orig && newItem) {
      for (const i in stateItems) {
        if (stateItems[i] === orig) {
          stateItems[i] = newItem
          break
        }
      }
    } else if (orig) {
      stateItems = stateItems.filter(si => si !== orig)
    } else if (newItem) {
      stateItems.push(newItem)
    }

    await commit(this.storeMutationKey, [...stateItems])

    if (this.useLocalstorage && localStorageKey) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify(stateItems),
      )
    }
  }

  async #updateItemsInStore(commit, newItems = null, localStorageKey = '') {
    await commit(this.storeMutationKey, [...newItems])

    if (this.useLocalstorage && localStorageKey) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify(newItems),
      )
    }
  }
}
