<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  data() {
    return {
      onLine: navigator.onLine,
    }
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  methods: {
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
    }
  },
  watch: {
    onLine(v) {
      this.$store.dispatch('setIsOnline', v)
    }
  },
  mounted() {
    this.$vuetify.theme.dark = this.$store.state.themeSettings.isDark

    if (this.$vuetify.theme.dark) {
      document.querySelector('body').style.backgroundColor = '#28243c'
    }

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    this.$store.dispatch('setIsOnline', navigator.onLine)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }
}
</script>
