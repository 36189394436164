<template>
  <div style="position: absolute;right: 11px;top: 36px; z-index: 100">
    <v-alert v-for="(globalAlert, i) in globalAlerts"
             dense
             transition="scroll-y-reverse-transition"
             dismissible
             class="mt-4"
             :v-key="i"
             :type="globalAlert.type || 'success'"
             :value="globalAlert._active">
      {{ globalAlert.text }}
    </v-alert>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState([
      'globalAlerts',
    ]),
  }
}
</script>
