import Vue from 'vue'
import Vuex from 'vuex'

import projectStore from './project-store'
import membersStore from './members-store'
import todoStore from './todo-store'
import documentsStore from './documents-store'
import quickLinksStore from './quick-links-store'
import helpersStore from './helpers-store'

import serverStatsStore from './server-stats-store'

import modulesStore from './modules-store'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    ...modulesStore.state,

    ...serverStatsStore.state,

    ...todoStore.state,
    ...projectStore.state,
    ...membersStore.state,
    ...documentsStore.state,
    ...quickLinksStore.state,
    ...helpersStore.state,
  },
  mutations: {
    ...modulesStore.mutations,

    ...serverStatsStore.mutations,

    ...todoStore.mutations,
    ...projectStore.mutations,
    ...membersStore.mutations,
    ...documentsStore.mutations,
    ...quickLinksStore.mutations,
    ...helpersStore.mutations,
  },
  actions: {
    ...modulesStore.actions,

    ...serverStatsStore.actions,

    ...todoStore.actions,
    ...projectStore.actions,
    ...membersStore.actions,
    ...documentsStore.actions,
    ...quickLinksStore.actions,
    ...helpersStore.actions,
  },
})

export default store
