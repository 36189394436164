<template>
  <v-navigation-drawer
    :permanent="!mobileViewport"
    :expand-on-hover="tableViewport"
    :value="isDrawerOpen"
    :mini-variant.sync="mini"
    app
    width="240"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary ml-1">
            Hub
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      dense
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiViewDashboardVariant"
      ></nav-menu-link>
      <nav-menu-link
        title="Projects"
        :to="{ name: 'projects' }"
        :icon="icons.mdiGarage"
      ></nav-menu-link>

      <favorites></favorites>

      <div v-for="enabledModulesSection in enabledModules">
        <nav-menu-section-title :title="enabledModulesSection.title"></nav-menu-section-title>
        <nav-menu-link
          v-for="enabledModule in enabledModulesSection.modules"
          v-if="enabledModule.showInSideNav"
          :v-key="enabledModule.routeName"
          :title="enabledModule.title"
          :to="enabledModule.routeTo"
          :icon="enabledModule.icon"
        ></nav-menu-link>
      </div>
    </v-list>

    <template v-slot:append>
      <div class="pa-4 pl-2 mb-0 text-no-wrap">
<!--        <router-link :to="{name: 'desktop-apps'}" class="text-decoration-none btm-raz-link" v-if="!isElectron">-->
<!--          <div class="text-xs mb-2 d-block ml-3">-->
<!--            Get Desktop App-->
<!--            <v-icon small>{{ icons.mdiArrowTopRight }}</v-icon>-->
<!--          </div>-->
<!--        </router-link>-->
        <a href="https://razzzila.com/contact" target="_blank" class="text-decoration-none btm-raz-link">
          <div class="text-xs d-block ml-3">
            Contact Us
            <v-icon small>{{ icons.mdiArrowTopRight }}</v-icon>
          </div>
        </a>
      </div>
      <div class="pa-2 text-no-wrap btm-raz-link">
        <a target="_blank" href="https://razzzila.com/">Razzzila</a> &copy; 2022
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiViewList,
  mdiArrowTopRight, mdiGarage, mdiViewDashboardVariant,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import Favorites from '../../../components/QuickLinks/Favorites.vue'
import {isElectron} from "@/helpers/helpers";
import {mapState} from "vuex";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
    Favorites,
  },
  setup() {
    return {
      icons: {
        mdiGarage,
        mdiViewList,
        mdiViewDashboardVariant,
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiArrowTopRight,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
      },
    }
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      isElectron: isElectron()
    }
  },
  computed: {
    ...mapState({
      enabledModules: 'enabledModules',
    }),
    mini() {
      return this.tableViewport
    },
    desktopViewport() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    mobileViewport() {
      return this.$vuetify.breakpoint.xsOnly
    },
    tableViewport() {
      return this.$vuetify.breakpoint.sm
    },
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.vertical-nav-header {
  padding-left: 18px;
}

.v-navigation-drawer--mini-variant .btm-raz-link {
  display: none;
}
.btm-raz-link {
  font-size: 13px;
  margin-left: 12px;
}
</style>
