import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

import './plugins/bootstrap'

import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth/src/v2'
import vuetify from './plugins/vuetify'
import App from './App.vue'

import auth from './plugins/auth'

import router from './router'
import store from './store'
import './registerServiceWorker'

// Set Vue router
Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://hub.razzzila.com/api'
Vue.use(VueAuth, auth)

Vue.router = router

// Set Vue authentication

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
