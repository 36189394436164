import bearer from '@websanova/vue-auth/src/drivers/auth/bearer'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x'

import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverOAuth2Google from '@websanova/vue-auth/src/drivers/oauth2/google.js'
import driverOAuth2Facebook from '@websanova/vue-auth/src/drivers/oauth2/facebook.js'

import axios from 'axios'
import router from '../router'

driverOAuth2Google.params.client_id = '1066051920086-qmrgm7c4cio1gfe18hj4j96iuoh3cmp8.apps.googleusercontent.com'
driverOAuth2Google.params.redirect_uri = 'auth/login/google'

driverOAuth2Facebook.params.client_id = '196729390739201'
driverOAuth2Facebook.params.redirect_uri = 'auth/login/facebook'

// Auth base configuration some of this options
// can be override in method calls
const config = {
  plugins: {
    http: axios, // Axios
    router,
    auth: bearer,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios, // Axios
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
      facebook: driverOAuth2Facebook,
    },
  },

  tokenDefaultName: 'vue-auth',
  tokenStore: ['localStorage'],
  rolesVar: 'role',
  registerData: { url: 'auth/register', method: 'POST', redirect: '/login' },
  loginData: {
    url: 'auth/login', method: 'POST', redirect: '', fetchUser: true,
  },
  logoutData: {
    url: 'auth/logout', method: 'POST', redirect: '/', makeRequest: true,
  },
  fetchData: { url: 'auth/user', method: 'GET', enabled: true },
  refreshData: {
    url: 'auth/refresh',
    method: 'GET',
    enabled: true,
    interval: 30,
  },
}

export default config
