<template>
  <v-app>
    <v-app-bar
      class="app-bar"
      app
      flat
      dense
    >
      <global-alert></global-alert>

      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">

          <v-app-bar-nav-icon
            v-if="mobileViewport"
            class="d-block d-lg-none me-2 ml-12"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>

          <project-switcher class="project-switcher"></project-switcher>

          <!--          <v-btn-->
<!--            icon-->
<!--            x-large-->
<!--          >-->
<!--            <v-badge avatar bordered overlap>-->
<!--              <template v-slot:badge>-->
<!--                <v-avatar color="error">-->
<!--                  20-->
<!--                </v-avatar>-->
<!--              </template>-->
<!--              <v-avatar color="primary" size="24">WD</v-avatar>-->
<!--            </v-badge>-->
<!--          </v-btn>-->

          <v-spacer></v-spacer>

          <span v-if="!isOnline" class="text-sm error--text">
            You Are Offline
          </span>
          <v-icon class="mr-12 ml-2 me-4" v-if="!isOnline" color="error">
            {{ icons.mdiWifiAlert }}
          </v-icon>

          <theme-switcher></theme-switcher>

          <notifications-bar></notifications-bar>

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <vertical-nav-menu class="vertical-nav-menu" :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <v-overlay :value="sectionOverlay">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import {mdiMagnify, mdiBellOutline, mdiGithub, mdiWifiAlert} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import ProjectSwitcher from '../components/ProjectSwitcher.vue'
import NotificationsBar from './components/NotificationsBar.vue'

import { mapState } from 'vuex'
import GlobalAlert from "@/layouts/components/GlobalAlert";

export default {
  components: {
    GlobalAlert,
    VerticalNavMenu,
    ThemeSwitcher,
    NotificationsBar,
    AppBarUserMenu,
    ProjectSwitcher,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiWifiAlert
      },
    }
  },
  computed: {
    ...mapState([
      'isOnline',
      'sectionOverlay',
    ]),
    mobileViewport() {
      return this.$vuetify.breakpoint.xsOnly
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

@include theme(v-app-bar) using ($material) {
  ::v-deep {
    height: 36px!important;
    border-bottom: 1px solid rgba(map-deep-get($material, 'primary-shade'), 0.22);

    .v-toolbar__content {
      height: 36px!important;
      padding: 0;

      .app-bar-search {
        .v-input__slot {
          padding-left: 18px;
        }
      }
    }
  }
}

.v-main {
  padding-top: 36px!important;
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.vertical-nav-menu {
  padding-top: 36px
}

.project-switcher {
  margin-left: 72px;
}
</style>
