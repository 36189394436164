import Syncer from './logic/syncer'

const syncerTasks = new Syncer({
  localstorageKey: 'quick-links-links',
  idFieldName: 'id',
  internalKey: 'list_internal_key',

  storeStateKey: 'quickLinksLinks',
  storeMutationKey: 'SET_QUICK_LINKS_LINKS',
})

const syncerLists = new Syncer({
  localstorageKey: 'quick-links-lists',
  idFieldName: 'id',
  internalKey: '_internal_key',

  storeStateKey: 'quickLinksLists',
  storeMutationKey: 'SET_QUICK_LINKS_LISTS',
})

const store = {
  state: {
    quickLinksLinks: [],
    quickLinksLists: [],
  },
  mutations: {
    SET_QUICK_LINKS_LISTS(state, todoLists) {
      state.quickLinksLists = todoLists
    },
    SET_QUICK_LINKS_LINKS(state, todoTasks) {
      state.quickLinksLinks = todoTasks
    },
  },
  actions: {
    async fetchLinks({ commit, state }) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }

      const projectId = state.currentProject ? state.currentProject.id : '0'

      await syncerLists.getItems(commit, projectId)
      await syncerTasks.getItems(commit, projectId)
    },
    async setQuickLinksLinks({ commit, state }, links) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }

      const projectId = state.currentProject ? state.currentProject.id : '0'
      await syncerTasks.sync(state, commit, projectId, null, links)
    },
    async setQuickLinksLists({ commit, state }, lists) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }

      const projectId = state.currentProject ? state.currentProject.id : '0'
      await syncerLists.sync(state, commit, projectId, null, lists)
    },
  },
}

export default store
