export function openNewTab(url) {
  // @TODO: if no protocol add https

  if (url.indexOf('http://') === 0) {
    // @TODO: show dialog that link unsecured
  } else if (url.indexOf('https://') !== 0 && url.indexOf('ftp://') !== 0) {
    url = `https://${url}`
  }

  if (!/^(ftp|http|https):\/\/[^ "]+$/.test(url)) {
    // @TODO: rise error
    alert(`Invalid Url: ${url}`)

    return false
  }

  const blank = window.open(url, '_blank')
  if (blank) {
    blank.focus()
  }
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function isElectron() {
  return navigator.userAgent.toLowerCase().indexOf(' electron/') > -1
}

export function getShortName(user) {
  return (user.name || user.email)[0].toUpperCase()
}
