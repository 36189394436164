<template>
  <v-app>
    <v-app-bar
      v-if="isElectron"
      class="app-bar"
      app
      flat
      dense
      height="36"
    ></v-app-bar>

    <global-alert></global-alert>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import GlobalAlert from "@/layouts/components/GlobalAlert";
import {isElectron} from "@/helpers/helpers";

export default {
  components: {
    GlobalAlert
  },
  methods: {
    isElectron: isElectron
  }
}
</script>

<style>
</style>
