import {
  mdiDrawingBox,
  mdiFile,
  mdiFileDocument,
  mdiFileExcel,
  mdiFileImage,
  mdiFileLink,
  mdiFilePdf,
  mdiPencilBox,
  mdiFolder,
} from '@mdi/js'
import Syncer from './logic/syncer'

const foldersFilesSyncer = new Syncer({
  localstorageKey: 'documents-folder-files',

  storeStateKey: 'files',
  storeMutationKey: 'SET_CURRENT_FOLDER_FILES',
})

const foldersFoldersSyncer = new Syncer({
  localstorageKey: 'documents-folder-folders',

  storeStateKey: 'folders',
  storeMutationKey: 'SET_CURRENT_FOLDER_FOLDERS',
})

const store = {
  state: {
    currentFolder: {
      id: '',
      name: 'Documents',
      parentFolders: [
        // {
        //   id: '3',
        //   name: 'parent folder',
        // },
      ],

      files: [],
      folders: [],
    },
    fileTypes: [
      {
        type: 'dir',
        name: 'Folder',
        icon: mdiFolder,
      },
      {
        type: 'doc',
        name: 'Document',
        icon: mdiFileDocument,
      },
      {
        type: 'doc',
        name: 'Document',
        icon: mdiFileDocument,
      },
      {
        type: 'sheet',
        name: 'Spread Sheet',
        icon: mdiFileExcel,
      },
      {
        type: 'draw',
        name: 'Drawing',
        icon: mdiDrawingBox,
      },
      {
        type: 'jam',
        name: 'Jamboard',
        icon: mdiPencilBox,
      },
      {
        type: 'img',
        name: 'Image',
        icon: mdiFileImage,
      },
      {
        type: 'link',
        name: 'Link',
        icon: mdiFileLink,
      },
      {
        type: 'pdf',
        name: 'PDF',
        icon: mdiFilePdf,
      },
      {
        type: 'custom',
        name: 'Other',
        icon: mdiFile,
      },
    ],
  },
  mutations: {
    SET_CURRENT_FOLDER(state, folder) {
      state.currentFolder = folder
    },
    SET_CURRENT_FOLDER_FILES(state, files) {
      state.currentFolder.files = files
    },
    SET_CURRENT_FOLDER_FOLDERS(state, folders) {
      state.currentFolder.folders = folders
    },
  },
  actions: {
    async fetchFolder({ commit, state }, folderId) {
      const projectId = state.currentProject ? state.currentProject.id : '0'
      folderId = folderId || ''

      const resp = await foldersFoldersSyncer.customAction(
        'getFolder',
        projectId,
        folderId,
      )

      if (resp.error) {
        alert(resp.msg)

        return
      }

      const { folder } = resp
      if (folder.parent_id) {
        folder.parentFolder = {
          id: folder.parent_id,
        }
      }

      commit('SET_CURRENT_FOLDER', folder)

      // commit('SET_CURRENT_FOLDER_FILES', folder.files)
      // commit('SET_CURRENT_FOLDER_FOLDERS', folder.folders)

      // foldersFoldersSyncer.getItems(commit, projectId, folder.id)
      // foldersFilesSyncer.getItems(commit, projectId, folder.id)
    },

    async addDocumentsFolderFile({ commit, state }, file) {
      const projectId = state.currentProject ? state.currentProject.id : '0'
      const folderId = state.currentFolder.id

      if (!state.currentFolder.files) {
        state.currentFolder.files = []
      }

      file.folder_id = folderId
      state.currentFolder.files.push(file)

      await foldersFilesSyncer.sync(
        state.currentFolder,
        commit,
        projectId,
        folderId,
        state.currentFolder.files,
      )
    },
    async addDocumentsFolderFolder({ commit, state }, folder) {
      const projectId = state.currentProject ? state.currentProject.id : '0'
      const folderId = state.currentFolder.id

      if (!state.currentFolder.folders) {
        state.currentFolder.folders = []
      }

      folder.parent_id = folderId
      state.currentFolder.folders.push(folder)

      await foldersFoldersSyncer.sync(
        state.currentFolder,
        commit,
        projectId,
        folderId,
        state.currentFolder.folders,
      )
    },

    async updateDocumentsFolderFile({ commit, state }, file) {
      const projectId = state.currentProject ? state.currentProject.id : '0'
      const folderId = state.currentFolder

      file._synced = false

      await foldersFilesSyncer.sync(
        state.currentFolder,
        commit,
        projectId,
        folderId,
        state.currentFolder.files,
      )
    },
    async updateDocumentsFolderFolder({ commit, state }, folder) {
      const projectId = state.currentProject ? state.currentProject.id : '0'
      const folderId = state.currentFolder

      folder._synced = false

      await foldersFoldersSyncer.sync(
        state.currentFolder,
        commit,
        projectId,
        folderId,
        state.currentFolder.folders,
      )
    },
  },
}

export default store
