import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/server-health',
    name: 'server-health',
    component: () => import('@/views/project/ServiceStatus.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/project/ProjectsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/accept-invite/:token',
    name: 'project-accept-invite',
    component: () => import('@/views/project/AcceptInvite.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: true,
    },
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/views/project/Teams.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/quick-links',
    name: 'quick-links',
    component: () => import('@/views/project/QuickLinks.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/todos',
    name: 'todo-lists',
    component: () => import('@/views/project/ToDoList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/documents/:id?',
    name: 'documents',
    component: () => import('@/views/project/Documents.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dumps',
    name: 'dumps',
    component: () => import('@/views/project/Dumps.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/view/:id',
    name: 'project-page',
    component: () => import('@/views/project/ProjectsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/create',
    name: 'project-create',
    component: () => import('@/views/project/CreateProject.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth/login/:provider?',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // await router.app.$store.dispatch('setSectionOverlay', false)

    try {
      if (!router.app.$auth.user()) {
        await router.app.$auth.fetch()
      }
    } catch (e) {
      next({ name: 'pages-login' })
    }

    if (!router.app.$auth.user()) {
      next({ name: 'pages-login' })
    } else {
      next()
    }
  } else {
    next()
  }

  if (to.matched.some(record => [
    'pages-login', 'pages-register'
  ].indexOf(record.meta.name) !== -1)) {
    if (router.app.$auth.user()) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
