<template>
  <div>
    <v-menu
      v-if="projects && projects.length"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          elevation="0"
          class="rounded-xl"
          v-bind="attrs"
          v-on="on"
        >
          {{ currentProjectName }}
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item) in projects"
          :key="item.id"
          @click="chooseProject(item)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <router-link
      v-if="!projects || !projects.length"
      :to="{ name:'project-create' }"
    >
      <v-btn
        color="primary"
        dark
        depressed
        small
        class="rounded-xl"
      >
        Create First Project
      </v-btn>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  mounted() {
    this.$store.dispatch('fetchProjects')
  },
  methods: {
    chooseProject(item) {
      this.$store.dispatch('setCurrentProject', item)

      // this.$router.go(this.$route.fullPath)
    },
  },
  computed: {
    ...mapState([
      'projects',
      'currentProject',
      'currentProjectName',
    ]),
  },
}
</script>

<style>

</style>
