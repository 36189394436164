<template>
  <v-fade-transition mode="out-in">
    <v-btn icon small>
      <v-icon
        :key="$vuetify.theme.dark"
        @click="changeTheme"
      >
        {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
      </v-icon>
    </v-btn>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import {mapState} from "vuex";

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  computed: {
    ...mapState([
      'themeSettings'
    ]),
  },
  watch: {
    themeSettings() {
      if (this.themeSettings.isDark !== undefined) {
        this.$vuetify.theme.dark = this.themeSettings.isDark
      }
    }
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.dispatch('setThemeSettings', {
        isDark: this.$vuetify.theme.dark
      })
    }
  }
}
</script>
