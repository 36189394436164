const localStorageKey = 'theme-settings'

const storedTheme = JSON.parse(
  localStorage.getItem(localStorageKey),
) || null

const store = {
  state: {
    isOnline: false,
    sectionOverlay: false,
    globalAlerts: [],
    themeSettings: storedTheme || {}
  },
  mutations: {
    SET_IS_ONLINE(state, status) {
      state.isOnline = status
    },
    SET_GLOBAL_ALERTS(state, alerts) {
      state.globalAlerts = alerts
    },
    SET_SECTION_OVERLAY(state, sectionOverlay) {
      state.sectionOverlay = sectionOverlay
    },
    SET_THEME_SETTINGS(state, themeSettings) {
      state.themeSettings = themeSettings
    },
  },
  actions: {
    addAlertMsg({ commit, state }, alert) {
      alert._active = true
      state.globalAlerts.push(alert)
      commit('SET_GLOBAL_ALERTS', state.globalAlerts)

      setTimeout(() => {
        alert._active = false
        setTimeout(() => {
          commit('SET_GLOBAL_ALERTS', state.globalAlerts.filter(e => e !== alert))
        }, 1000)
      }, alert.timeout || 3000)
    },
    setSectionOverlay({ commit }, sectionOverlay) {
      commit('SET_SECTION_OVERLAY', sectionOverlay)
    },
    setIsOnline({ commit }, status) {
      commit('SET_IS_ONLINE', status)
    },

    setThemeSettings({ commit, dispatch }, payload) {
      // @TODO: sync to server
      commit('SET_THEME_SETTINGS', payload)
      localStorage.setItem(
        localStorageKey,
        JSON.stringify(payload),
      )
    },
  },
}

export default store
