import axios from 'axios'

const store = {
  state: {
    invites: [],
    membersGroups: [
      { id: 1, name: 'Admin' },
      { id: 2, name: 'Developer' },
      { id: 3, name: 'Manager' },
    ],
    currentProjectUsers: [],
    currentProjectInvites: [],
  },
  mutations: {
    SET_PROJECT_USERS_LIST(state, list) {
      state.currentProjectUsers = list
    },
    SET_PROJECT_INVITES_LIST(state, list) {
      state.currentProjectInvites = list
    },
  },
  actions: {
    getUsersList({ commit, state }) {
      if (!state.currentProject) {
        commit('SET_PROJECT_USERS_LIST', [])
        return
      }

      axios
        .post('project/get-users-list', {
          project_id: state.currentProject.id,
        })
        .then(response => {
          commit('SET_PROJECT_USERS_LIST', response.data.entities)
        })
        .catch(error => console.log(error))
    },
    getInvitesList({ commit, state }) {
      if (!state.currentProject) {
        commit('SET_PROJECT_INVITES_LIST', [])
        return
      }

      axios
        .post('project/get-invites-list', {
          project_id: state.currentProject.id,
        })
        .then(response => {
          commit('SET_PROJECT_INVITES_LIST', response.data.entities)
        })
        .catch(error => console.log(error))
    },

    resendInvite({ commit, state }, inviteId) {
      axios
        .get('project/resend-invite/' + inviteId)
        .then(resp => {
          alert('Re-sent successfully')
        })
        .catch(error => console.log(error))
    },

    deleteInvite({ commit, state, dispatch }, inviteId) {
      axios
        .post('project/delete-invite/' + inviteId)
        .then(resp => {
          dispatch('addAlertMsg', {text: 'Deleted successfully'})
          commit(
            'SET_PROJECT_INVITES_LIST',
            state.currentProjectInvites.filter(iv => iv.id !== inviteId)
          )
        })
        .catch(error => {
          console.log(error)
          dispatch('addAlertMsg', {type: 'error', text: 'Something gone wrong'})
        })
    },

    deleteUser({ commit, state, dispatch }, userId) {
      axios
        .post('project/delete-user/' + userId)
        .then(resp => {
          dispatch('addAlertMsg', {text: 'Deleted successfully'})
          commit(
            'SET_PROJECT_USERS_LIST',
            state.currentProjectUsers.filter(iv => iv.id !== userId)
          )
        })
        .catch(error => {
          console.log(error)
          dispatch('addAlertMsg', {type: 'error', text: 'Something gone wrong'})
        })
    },

    invite({ commit, state }, invite) {
      axios
        .post('project/invite-user', {
          email: invite.email,
          role: invite.role,
          project_id: state.currentProject.id
        })
        .then(response => {
          if (response.data.status === 'success') {
            state.currentProjectInvites.push({
              user_email: invite.email,
              role: invite.role,
              project_id: state.currentProject.id
            })
            commit('SET_PROJECT_INVITES_LIST', state.currentProjectInvites)
          } else {
            alert(response.data.errors.join("\n"))
          }
        })
        .catch(error => console.log(error))
    },
  },
}

export default store
