import Syncer from './logic/syncer'

const syncerTasks = new Syncer({
  localstorageKey: 'todo-tasks',
  internalKey: 'list_internal_key',

  storeStateKey: 'todoTasks',
  storeMutationKey: 'SET_TODO_TASKS',
})

const syncerLists = new Syncer({
  localstorageKey: 'todo-lists',
  internalKey: '_internal_key',

  storeStateKey: 'todoLists',
  storeMutationKey: 'SET_TODO_LISTS',
})

const store = {
  state: {
    todoTasks: [],
    todoLists: [],
  },
  mutations: {
    SET_TODO_LISTS(state, todoLists) {
      state.todoLists = todoLists
    },
    SET_TODO_TASKS(state, todoTasks) {
      state.todoTasks = todoTasks
    },
  },
  actions: {
    async fetchTodos({ commit, state }) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }

      const projectId = state.currentProject.id

      await syncerLists.getItems(commit, projectId)
      await syncerTasks.getItems(commit, projectId)
    },
    async setTodoTasks({ commit, state }, tasks) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }
      const projectId = state.currentProject.id

      await syncerTasks.sync(state, commit, projectId, null, tasks)
    },
    async setTodoLists({ commit, state }, lists) {
      if (!state.currentProject || !state.currentProject.id) {
        // @TODO: show msg
        return
      }
      const projectId = state.currentProject.id

      await syncerLists.sync(state, commit, projectId, null, lists)
    },
  },
}

export default store
